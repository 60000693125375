import { Field } from '@wf-mfe-maestro/api';

import { fieldsSubject$ } from '../state';

const addField = (fields: Field[], newField: Field) => [...fields, newField];

export const getNewFieldsAfterCreateFromFields = (fields: Field[], newField: Field) =>
  addField(fields, newField);

export const getNewFieldsAfterCreate = (newField: Field) =>
  addField(fieldsSubject$.getValue(), newField);
