import { FieldDTO } from '@wf-mfe-maestro/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { IRootState } from '@store';

import { getNewFieldsAfterUpdate } from '../../service';

export const updateRecordTypeFieldReducer = (
  state: IRootState,
  { payload }: PayloadAction<FieldDTO>
) => {
  const [newFields] = getNewFieldsAfterUpdate(state.fields, payload);
  state.fields = newFields;
};
