import { Field } from '@wf-mfe-maestro/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { IRootState } from '@store';

import { getNewFieldsAfterRemove } from '../../service';

export const deleteRecordTypeFieldReducer = (
  state: IRootState,
  { payload }: PayloadAction<Field>
) => {
  state.fields = getNewFieldsAfterRemove(state.fields, payload);

  if (state.recordType.externalOptions) {
    const externalOptionsFields = state.recordType.externalOptions.fields;
    const connectedKey = Object.keys(externalOptionsFields).find(
      (fieldId) => externalOptionsFields[fieldId] === payload.id
    );
    if (connectedKey) {
      // TODO: check this part later when all migration is done
      delete externalOptionsFields[connectedKey];
    }
  }
};
